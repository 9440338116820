import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SesionComponent } from './PincWeb/sesion/sesion.component';
import { ClienteComponent } from "./PincWeb/Cliente/cliente.component";
import { LoginComponent } from './PincWeb/sesion/login/login.component';
import { LoginAdminComponent } from './PincWeb/sesion/login-admin/login-admin.component';
import { GestionComponent } from './PincWeb/Gestion/gestion.component';
import { CompletarFirmarComponent } from './PincWeb/Cliente/completar-firmar/completar-firmar.component';
import { DocumentoComponent } from './PincWeb/Cliente/documento/documento.component';
import { FirmaComponent } from './PincWeb/Cliente/firma/firma.component';
import { GestionUsuariosComponent } from './PincWeb/Gestion/gestion-usuarios/gestion-usuarios.component';
import { DetallesUsuarioComponent } from './PincWeb/Gestion/detalles-usuario/detalles-usuario.component';
import { GestionPagareComponent } from './PincWeb/Gestion/gestion-pagare/gestion-pagare.component';
import { HomeAdminComponent } from './PincWeb/Gestion/home-admin/home-admin.component';
import { LoginEmpleadoGuardGuard } from "./General/login-empleado-guard.guard";
import { LoginGuardGuard } from "./General/login-guard.guard";
import { PincWebComponent } from './PincWeb/pincweb.component';
import { HomeClienteComponent } from './PincWeb/Cliente/home-cliente/home-cliente.component';
import { MisPagaresComponent } from './PincWeb/Cliente/mis-pagares/mis-pagares.component';
import { OlvidoClaveClienteComponent } from './PincWeb/sesion/olvido-clave-cliente/olvido-clave-cliente.component';
import { OlvidoClaveEmpleadoComponent } from './PincWeb/sesion/olvido-clave-empleado/olvido-clave-empleado.component';
import { CambioClaveInicialComponent } from './PincWeb/Cliente/cambio-clave-inicial/cambio-clave-inicial.component';
import { ReiniciarClaveComponent } from './PincWeb/Gestion/reiniciar-clave/reiniciar-clave.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/FecyrWeb/sesion/login',
    pathMatch: 'full'
  },
  {
    path: 'FecyrWeb',
    component: PincWebComponent,
    children: [
      {
        path: 'gestion',
        component: GestionComponent,
        canActivate: [LoginEmpleadoGuardGuard],
        children: [
          {
            path: 'homeMenu',
            canActivate: [LoginEmpleadoGuardGuard],
            component: HomeAdminComponent
          },
          {
            path: 'gestion-usuarios',
            component: GestionUsuariosComponent,
            canActivate: [LoginEmpleadoGuardGuard],
            children: [
              {
                path: 'detalles-usuario',
                component: DetallesUsuarioComponent,
                canActivate: [LoginEmpleadoGuardGuard]
              },
            ]
          },
          {
            path: 'gestion-pagare',
            canActivate: [LoginEmpleadoGuardGuard],
            component: GestionPagareComponent,
          },
          {
            path: 'reiniciarClaveCliente',
            canActivate: [LoginEmpleadoGuardGuard],
            component: ReiniciarClaveComponent,
          }
        ]
      },
      {
        path: 'Cliente',
        canActivate: [LoginGuardGuard],
        component: ClienteComponent,
        children: [
          {
            path: 'homeCliente',
            canActivate: [LoginGuardGuard],
            component: HomeClienteComponent
          },
          {
            path: 'cambioClaveInicial',
            canActivate: [LoginGuardGuard],
            component: CambioClaveInicialComponent
          },
          {
            path: 'MisPagares',
            canActivate: [LoginGuardGuard],
            component: MisPagaresComponent
          },
          {
            path: 'completar-firmar',
            canActivate: [LoginGuardGuard],
            component: CompletarFirmarComponent,
            children: [
              {
                path: 'documento',
                canActivate: [LoginGuardGuard],
                component: DocumentoComponent
              },
              {
                path: 'firma',
                canActivate: [LoginGuardGuard],
                component: FirmaComponent
              },
            ]
          },
        ]
      },
      {
        path: 'sesion',
        component: SesionComponent,
        children: [
          {
            path: 'login',
            component: LoginComponent
          },
          {
            path: 'olvidoClave',
            component: OlvidoClaveClienteComponent
          },
          {
            path: 'loginAdmin',
            component: LoginAdminComponent
          },
          {
            path: 'olvidoClaveEmpleado',
            component: OlvidoClaveEmpleadoComponent
          }
        ]
      },
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/FecyrWeb/sesion/login'
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: false })],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled'
    })
  ],

  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [SesionComponent];
