import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PagaredigitalimpService } from '../../../services/ImplementacionServicios/pagaredigitalimp.service';
import { GestorSolPagareImpService } from '../../../services/ImplementacionServicios/gestor-sol-pagare-imp.service';
import { Usuario } from 'src/app/Model/usuario';
import { SolicitudPagare } from 'src/app/Model/solicitud-pagare';
import { RespuestaGenerica } from 'src/app/Model/Response/RespuestaGenerica';
import { ResponseDetalleFirmaPagare, firmas } from 'src/app/Model/Response/response-detalle-firma-pagare';
import { ResponseObtenerDocumento, ResponseObtenerMultiplesDocs } from 'src/app/Model/Response/response-obtener-documento';
import { ResponseSolicitudPagare } from 'src/app/Model/Response/response-solicitud-pagare';
import { TokenImpService } from 'src/app/Services/ImplementacionServicios/token-imp';
import { SolicitudPagareRta } from 'src/app/Model/Response/solicitud-pagare-rta';
import { SeguridadClienteImp } from 'src/app/Services/ImplementacionServicios/seguridad-cliente-imp';
import { MessageService } from 'primeng/api';
import { RespuestaProcesoPagare } from 'src/app/Model/Response/RespuestaProcesoPagare';
import { CargarTodosUsuariosImpService } from 'src/app/services/ImplementacionServicios/cargar-todos-usuarios-imp';
@Component({
  selector: 'app-mis-pagares',
  templateUrl: './mis-pagares.component.html',
  styleUrls: ['./mis-pagares.component.css']
})
export class MisPagaresComponent implements OnInit {

  private usuarioCurrent: Usuario;
  public txtFechaExpDoc: any;
  public displayModalFirmas: boolean;
  public displayModalValIdent: boolean;
  public displayModalInicioProceso: boolean;
  public listFirmas: firmas[];
  public solicitudPagareSelected: SolicitudPagareRta;
  public listMisPagares: SolicitudPagareRta[];

  constructor(
    private pagareDigitalServiceImp: PagaredigitalimpService,
    private gestorSolPagareImpServie: GestorSolPagareImpService,
    private cargarTodosUsuariosImpService: CargarTodosUsuariosImpService,
    private tokenServiceImp: TokenImpService,
    private seguridadService: SeguridadClienteImp,
    private messageService: MessageService,
    private route: Router,
    private SpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.currentUser();
    this.obtenerMisPagares();
    this.solicitudPagareSelected = null;
  }
  currentUser() {
    let codCurrentUser = this.tokenServiceImp.getCodUser();
    this.usuarioCurrent = { ICodigo: codCurrentUser };
  }

  obtenerMisPagares() {
    this.SpinnerService.show("sp6");
    this.txtFechaExpDoc = null;
    let requestSolPagare: SolicitudPagare;
    requestSolPagare = { IUsuarioDeudor: this.usuarioCurrent }
    this.pagareDigitalServiceImp.cargarMisPagare(requestSolPagare).then(
      (response: ResponseSolicitudPagare) => {
        this.SpinnerService.hide("sp6");
        if (response != null && response != undefined && response.flag) {
          this.listMisPagares = response.listaSolicitudPagare;
        } else {
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Error!', detail: 'No se pudo obtener las solicitudes, intente de nuevo.'});
        }

      }
    );
  }

  actualizarEstado(xiIdProceso: string) {
    this.SpinnerService.show('sp6');
    this.gestorSolPagareImpServie
      .cargarEstadoPagare(xiIdProceso)
      .then((data: RespuestaGenerica) => {
        this.SpinnerService.hide('sp6');
        if (data.bRta === false) {
          this.messageService.add({
            key: 'msgToast',
            severity: 'warn',
            summary: 'Alerta!',
            detail: data.sMsj,
          });
        } else {
          this.messageService.add({
            key: 'msgToast',
            severity: 'success',
            summary: '¡Éxito!',
            detail: data.sMsj,
          });
          this.obtenerMisPagares();
        }
      })
      .catch((error) => {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'error',
          summary: '¡Error!',
          detail: 'Ha ocurrido un error de conexión, intente de nuevo',
        });
      });
  }

  actualizarEstadoPagare(xICodigo: number) {
    this.SpinnerService.show("sp6");
    let requestSolPagare: SolicitudPagare;
    requestSolPagare = { ICodigo: xICodigo }
    this.pagareDigitalServiceImp.actualizarEstadoPagare(requestSolPagare).then(
      (data: RespuestaGenerica) => {
        this.messageService.clear();
        this.SpinnerService.hide("sp6");
        if (data.bRta == false) {
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Error!', detail: data.sMsj });
        } else {
          this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Éxito!', detail: data.sMsj });
          this.obtenerMisPagares();
        }
      }
    ).catch((error) => {

    });
  }

  detalleFirmasPagare(xiIdProceso: string) {
    this.SpinnerService.show("sp6");
    this.gestorSolPagareImpServie.cargarDetalleFirmaDocumento(xiIdProceso).then(
      (data: ResponseDetalleFirmaPagare) => {
        this.SpinnerService.hide("sp6");
        if (data.bRta == false) {
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Error!', detail: data.sMsg, life: 10000 });
        } else {
          this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Éxito!', detail: data.sMsg, life: 10000 });
          this.displayModalFirmas = true;
          this.listFirmas = data.firmas;
        }
      }
    );
  }

  obtenerMultiplesDocs(xICodigo: number, sNumRadic: string){
    this.SpinnerService.show('sp6');
    let requestSolPagare: SolicitudPagare;
    requestSolPagare = { ICodigo: xICodigo };
    this.gestorSolPagareImpServie.obtenerMultiplesDocs(requestSolPagare).then((data: ResponseObtenerMultiplesDocs) => {
      if (
        data != null &&
        data != 'ERROR' &&
        data.respuesta.mensaje != null &&
        data.respuesta.codigo == 1
      ) {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'success',
          summary: '¡Exito!',
          detail: data.respuesta.mensaje,
        });
        for (const documento of data.documentos) {
          let link = document.createElement('a');
          link.download = `Documento${documento.nombre}` + sNumRadic + '.pdf';
          link.href = 'data:application/octet-stream;base64,' + documento.base64;
          link.click();
        }
      } else {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'error',
          summary: '¡Alerta!',
          detail: data.respuesta.mensaje,
        });
      }
    });
  }

  ObtenerDocumentoPagare(xICodigo: number, sNumRadic: string) {
    this.SpinnerService.show("sp6");
    let requestSolPagare: SolicitudPagare;
    requestSolPagare = { ICodigo: xICodigo }
    this.gestorSolPagareImpServie.obtenerDocumentoPagare(requestSolPagare).then(
      (data: ResponseObtenerDocumento) => {
        if (data != null && data != "ERROR" && data.respuestaBase != null && data.respuestaBase.codigo == 1) {
          this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Exito!', detail: data.respuestaBase.mensaje, life: 10000 });
          var link = document.createElement('a');
          link.download = 'Pagare_' + sNumRadic + '.pdf';
          link.href = 'data:application/octet-stream;base64,' + data.archivoBase64;
          link.click();
          this.SpinnerService.hide("sp6");
        } else {
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Error!', detail: data.respuestaBase.mensaje, life: 10000 });
          this.SpinnerService.hide("sp6");
        }
      }
    );
  }

  goValidaIdentidad(solPagare: SolicitudPagareRta) {
    this.solicitudPagareSelected = solPagare;
    this.displayModalValIdent = true;
    this.displayModalInicioProceso = false;
    this.txtFechaExpDoc = null;
  }

  cancelarValidacion() {
    this.displayModalValIdent = false;
    this.displayModalInicioProceso = false;
    this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Alerta!', detail: 'Ha cancelado la validación de identidad para la firma del pagaré.' });
  }


  cancelarProcesoPagare() {
    this.displayModalValIdent = false;
    this.displayModalInicioProceso = false;
    this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Alerta!', detail: 'Ha cancelado el inicio de proceso de la firma del pagaré.' });
  }

  validarIdentidad() {
    this.SpinnerService.show("sp6");
    let aux;
    let txtFechaExpDoc = this.txtFechaExpDoc;
    let xiCodUser = this.tokenServiceImp.getCodUser();
    if (txtFechaExpDoc != null) {
      if (this.tokenServiceImp.getNombres().includes("AFIANZATE")) {
        this.cargarTodosUsuariosImpService.BuscarPersonaByIdentAndPerfil(2,btoa('1'),btoa('28428824')).then((data:any) => {
          aux = data.persona;
          this.seguridadService.validarIdentidad(aux.ICodigo, txtFechaExpDoc).then(
            (data: RespuestaGenerica) => {
              this.SpinnerService.hide("sp6");
              if (data.bRta == false) {
                this.messageService.add({ key: 'msgDiaglogValident', severity: 'warn', summary: '¡Atención!', detail: data.sMsj, life: 10000 });
              } else {
                this.messageService.add({ key: 'msgDiaglogValident', severity: 'success', summary: '¡Éxito!', detail: data.sMsj, life: 10000 });
                this.displayModalValIdent = false;
                this.displayModalInicioProceso = true;
              }
            }
          );
        });
      } else {
        this.seguridadService.validarIdentidad(xiCodUser, txtFechaExpDoc).then(
          (data: RespuestaGenerica) => {
            this.SpinnerService.hide("sp6");
            if (data.bRta == false) {
              this.messageService.add({ key: 'msgDiaglogValident', severity: 'warn', summary: '¡Atención!', detail: data.sMsj, life: 10000 });
            } else {
              this.messageService.add({ key: 'msgDiaglogValident', severity: 'success', summary: '¡Éxito!', detail: data.sMsj, life: 10000 });
              this.displayModalValIdent = false;
              this.displayModalInicioProceso = true;
            }
          }
        );
      }
    } else {
      this.SpinnerService.hide("sp6");
      this.messageService.add({ key: 'msgDiaglogValident', severity: 'warn', summary: '¡Alerta!', detail: 'Debe ingresar la fecha de expedición del documento', life: 10000 });
    }
  }

  generarPagare() {
    this.SpinnerService.show("sp6");
    let requestSolPagare: SolicitudPagare;
    requestSolPagare = { ICodigo: this.solicitudPagareSelected.iCodigo, IUsuarioDeudor: this.usuarioCurrent }
    this.pagareDigitalServiceImp.generarPagare(requestSolPagare).then(
      (data: RespuestaProcesoPagare) => {
        this.SpinnerService.hide("sp6");
        if (data.bRta == false) {
          this.messageService.add({ key: 'msgDiaglogProceso', severity: 'warn', summary: '¡Atención!', detail: data.sMsj, life: 10000 });
        } else {
          this.messageService.add({ key: 'msgDiaglogProceso', severity: 'success', summary: '¡Éxito!', detail: data.sMsj, life: 10000 });
          this.messageService.add({ key: 'msgToast', severity: 'success', summary: '¡Éxito!', detail: data.sMsj, life: 10000 });
          this.displayModalValIdent = false;
          this.displayModalInicioProceso = false;
          this.actualizarEstado(this.solicitudPagareSelected.iCodigo.toString());
        }
      }
    );
  }



}


