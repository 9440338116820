import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { LoginServiceImp } from '../../../Services/ImplementacionServicios/login-service-imp';
import { DialogComponent } from '../../pages/dialog/dialog.component';
import { TokenImpService } from '../../../Services/ImplementacionServicios/token-imp';
import { CargaDatosImpService } from '../../../services/ImplementacionServicios/carga-datos-imp';


import { RespuestaTipoPerfil, TipoPerfil } from "../../../Model/Response/RespuestaTipoPerfil";
import { TipoIdentificacion, RespuestaTipoIdent } from '../../../Model/Response/RespuestaTipoIdent';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.css']
})
export class LoginAdminComponent implements OnInit {

  public listPerfilEmpleados: TipoPerfil[];
  public listTipoIdent: TipoIdentificacion[];

  constructor(
    private route: Router,
    private loginServiceImp: LoginServiceImp,
    private SpinnerService: NgxSpinnerService,
    private jwt: TokenImpService,
    private cargaDatosServiceImp: CargaDatosImpService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.listPerfilEmpleados = null;
    this.listTipoIdent = null;
    this.obtenerPerfilesEmpleados();
    this.obtenerTiposIdent();
    this.jwt.clearStorage();
  }

  obtenerPerfilesEmpleados() {
    this.SpinnerService.show("sp6");
    this.cargaDatosServiceImp.tipoPerfil().then(
      (response: RespuestaTipoPerfil) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide("sp6");
          this.listPerfilEmpleados = response.listaTipoPerfil;
        } else {
          this.SpinnerService.hide("sp6");
        }
      }
    )
  }

  obtenerTiposIdent() {
    this.SpinnerService.show("sp6");
    this.cargaDatosServiceImp.tiposIden().then(
      (response: RespuestaTipoIdent) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide("sp6");
          this.listTipoIdent = response.listaTipoIdent;
        } else {
          this.SpinnerService.hide("sp6");
        }
      }
    )
  }


  iniciarSesion(iTipoPerfil: string, iTipoIdent: any, ident: any, clave: any) {
    this.SpinnerService.show("sp6");
    this.loginServiceImp.loginEmpleado(iTipoPerfil, iTipoIdent, btoa(ident), btoa(clave)).then(
      (data: any) => {
        if (data == "error") {
          this.SpinnerService.hide("sp6");
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Error!', detail: "Ha ocurrido un error de conexión" });
          this.jwt.clearStorage();
        } else {
          this.jwt.guardarTokenAdmin(data.jwt);
          this.jwt.guardarDatosUsuario(ident);
          if (!this.jwt.getbRtaEmpleado()) {
            this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: this.jwt.getsMsjEmpleado() });
            this.SpinnerService.hide("sp6");
            this.jwt.clearStorage();
          } else {
            this.messageService.add({ key: 'msgToast', severity: 'info', summary: '¡Bienvenido!', detail: this.jwt.getsMsjEmpleado() });
            this.SpinnerService.hide("sp6");
            this.route.navigate(['FecyrWeb/gestion/homeMenu']);
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.messageService.add({ key: 'msgToast', severity: 'error', summary: '¡Error!', detail: 'Ha ocurrido un problema de conexión' });
        this.SpinnerService.hide("sp6");
        this.jwt.clearStorage();
      })

  }

  async validarlogin(form: NgForm) {
    if (form.valid) {
      let iTipoPerfil = form.form.value.tipoPerfil;
      let iTipoIdent = form.form.value.tipoIdent;
      let ident = form.form.value.usuario;
      let clave = form.form.value.clave;
      this.SpinnerService.show("sp6");
      if (iTipoPerfil != null && iTipoPerfil != "" && iTipoIdent != null && iTipoIdent != "") {
        if (ident != null && ident != "" && clave != null && clave != "") {
          this.iniciarSesion(iTipoPerfil, iTipoIdent, ident, clave);
        } else {
          this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: 'Por favor verifique el fomrulario, debe ingresar información de usuario y clave.' });
          this.SpinnerService.hide("sp6");
        }
      } else {
        this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: 'Por favor verifique el fomrulario, debe ingresar información de tipo de perfil y tipo de identificación.' });
        this.SpinnerService.hide("sp6");
      }
    } else {
      this.messageService.add({ key: 'msgToast', severity: 'warn', summary: '¡Atención!', detail: 'No se ha validado el formulario correctamente.' });
      this.SpinnerService.hide("sp6");
    }
  }

  cerrarVentanaEmergente() {
    this.SpinnerService.hide();
  }

  CambiarClave() {
    this.route.navigate(['FecyrWeb/sesion/olvidoClaveEmpleado']);
  }
}
