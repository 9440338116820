import { PersonaRe, PersonaCodeudor } from "../DTO/PersonaRegistry";

export class RequestCrearPagare {
    iTipoPerfilDeudor?: number;
    personaDeudor?: PersonaRe;
    iTipoPerfilCodeudor?: number;
    requiereCodeudor?: boolean;
    numPagare?: number;
}

