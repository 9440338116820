<ngx-spinner name="sp6" type="timer" size="medium">
  <p style="color: white"> Espere un momento... </p>
</ngx-spinner>
<div class="encabezado">
  <h1 class="title">Solicitudes Firma Pagaré</h1>
</div>

<form class="login100-form was-validated" ngNativeValidate #infoCodeudor="ngForm" id="infoCodeudor" name="infoCodeudor"
  autocomplete="off">
  <div class="contenedor" id="contenedor">
    <p-toast position="top-right" key="msgToast"></p-toast>
    <div class="row">
      <div class="row">
        <div class="card">
          <p-toolbar styleClass="p-mb-5">
            <button pButton pRipple label="Agregar Pagaré" icon="pi pi-plus" class="p-button-raised p-mr-4"
              (click)="showModalNewInfoCodeudor()"></button>
            <button pButton pRipple label="Actualizar" icon="pi pi-refresh" class="p-button-raised p-mr-4"
              (click)="cargarTableSolPagare()"></button>
          </p-toolbar>
          <p-table #dt [value]="dataSource" dataKey="id" styleClass="p-datatable-responsive-demo"
            [globalFilterFields]="['iNumRadic','iNumPagare','sNombreAsociado','sDecTipoEstadoSolPagare']" [rows]="10"
            [paginator]="true" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} solicitudes"
            [showCurrentPageReport]="true">
            <!-- <div styleClass="p-datatable-responsive p-datatable p-component"> -->
            <ng-template pTemplate="caption">
              <div class="p-d-flex p-ai-center p-jc-between">
                <h5 class="p-m-0">Listado de solicitudes de pagaré</h5>
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Buscar..." class="amplio" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th style="text-align: center; font-size: 14px;" pSortableColumn="iNumRadic">N° Radicado<p-sortIcon
                    field="iNumRadic"></p-sortIcon>
                </th>
                <th style="text-align: center; font-size: 14px;" pSortableColumn="iNumPagare">N° Pagaré<p-sortIcon
                  field="iNumPagare"></p-sortIcon>
              </th>
                <th style="text-align: center; font-size: 14px;" pSortableColumn="sNombreAsociado">Asociado
                  <p-sortIcon field="sNombreAsociado">
                  </p-sortIcon>
                </th>
                <th style="text-align: center; font-size: 14px;" pSortableColumn="sDecTipoEstadoSolPagare">Estado
                  <p-sortIcon field="sDecTipoEstadoSolPagare"></p-sortIcon>
                </th>
                <th style="text-align: center; font-size: 14px;">Fecha Solicitud
                  <!-- <p-sortIcon field="sFechaSolPagare"></p-sortIcon> -->
                </th>
                <th style="text-align: center; font-size: 14px;">Fecha Inicio Proceso
                  <!-- <p-sortIcon field="sFechaFinProceso"></p-sortIcon> -->
                </th>
                <th style="text-align: center; font-size: 14px;">Fecha Fin Proceso</th>
                <th style="text-align: center; font-size: 14px;">Acciones</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-pp>
              <tr>
                <td style="text-align: center;">{{pp.iNumRadic }} </td>
                <td style="text-align: center;">{{pp.iNumPagare }} </td>
                <td style="text-align: center"> {{pp.sNombreAsociado}} </td>
                <td style="text-align: center"> {{pp.sDecTipoEstadoSolPagare}} </td>
                <td style="text-align: center"> {{pp.sFechaSolPagare }} </td>
                <td style="text-align: center"> {{pp.sFechaInicioProceso }}</td>
                <td style="text-align: center"> {{pp.sFechaFinProceso}} </td>
                <td>
                  <button pButton pRipple icon="pi pi-users" label="Firmas"
                    class="p-button-sm p-button-rounded p-button-secondary p-button-outlined separacion"
                    (click)="detalleFirmasPagare(pp.iCodigo)"></button>
                  <button pButton pRipple icon="pi pi-refresh" label="Actualizar"
                    class="p-button-sm p-button-rounded p-button-info  p-button-outlined separacion"
                    (click)="actualizarEstado(pp.iCodigo)"></button>
                  <button pButton pRipple icon="pi pi-replay" label="Reenviar OTP" *ngIf="pp.iCodTipoEstadoSolPagare==1 || pp.iCodTipoEstadoSolPagare==5"
                    class="p-button-sm p-button-rounded p-button-warning  p-button-outlined separacion"
                    (click)="ReenviarOTP(pp.iCodigo)"></button>
                  <button pButton pRipple icon="pi pi-download" label="Descargar" *ngIf="pp.iCodTipoEstadoSolPagare==3"
                    class="p-button-sm p-button-rounded  p-button-help  p-button-outlined separacion"
                    (click)="obtenerMultiplesDocs(pp.iCodigo,pp.iNumRadic)"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <div class="p-d-flex p-ai-center p-jc-between">
                En total hay {{dataSource ? dataSource.length : 0 }} solicitudes de pagaré.
              </div>
            </ng-template>
            <!-- </div> -->
          </p-table>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Dialog con detalle de firmas -->
<p-dialog header="Firmas de Pagaré" [(visible)]="displayModalFirmas" [style]="{width: '450vw'}" [baseZIndex]="10000"
    [blockScroll]="false" [draggable]="false" [resizable]="true" [maximizable]="true">
    <p-table [value]="listFirmas" [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
            Firmas del Pagaré
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="text-align: center;">N° de Identificación</th>
                <th style="text-align: center;">Nombres</th>
                <th style="text-align: center;">Estado Firma</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-f>
            <tr>
                <td style="text-align: center;">{{f.identificacion}}</td>
                <td style="text-align: center;">{{f.nombreFirmante}}</td>
                <td style="text-align: center;">{{f.estadoFirma}}</td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>

<!-- DIALOG ADD -->
<p-dialog header="Registro información Pagaré" [(visible)]="displayModalInfoCodeudor" [modal]="true"
  [style]="{'width': '90vw'}" [blockScroll]="true" [baseZIndex]="1000" [draggable]="false" [resizable]="false"
  styleClass="p-fluid" [maximizable]="true">
  <p-toast position="top-right" key="msgDiaglogValident"></p-toast>
  <p-messages></p-messages>
  <div class="row filas col-lg-12">
    <div class="col-md-12 col-12">
      <p class="TxtInput ">¿El usuario ya esta registrado?</p>
      NO
      <p-inputSwitch [(ngModel)]="isAsociado" (click)="reiniciarCampos()" name="cAsociado" id="cAsociado">
      </p-inputSwitch>
      SI
    </div>
    <div class="col-lg-3 col-md-12 col-12" *ngIf="isAsociado">
      <label for="tipoPerfilFind" class="TxtInput mb-1">Tipo Perfil</label>
      <select id="tipoPerfilFind" class="form-control selectAcompañamiento" required="isAsociado"
        [(ngModel)]="tipoPerfilFind" name="tipoPerfilFind" [disabled]="informacion">
        <option value="{{item.ICodigo}}" *ngFor="let item of listPerfilCiente" class="form-control">
          {{item.CDescripcion}}
        </option>
      </select>
    </div>
    <div class="col-lg-3 col-md-8 col-4" *ngIf="isAsociado">
      <label class="TxtInput mb-1">
        Tipo identificación
      </label>
      <select id="iTipoIdentFind" class="form-control selectAcompañamiento" name="iTipoIdentFind" required="isAsociado"
        [(ngModel)]="tipoIndent" #iTipoIdentFind="ngModel">
        <option value="{{item.ICodigo}}" *ngFor="let item of listTipoIdent" class="form-control">
          {{item.CDescripcion}}
        </option>
      </select>
    </div>

    <div class="col-lg-3 col-md-5 col-4" *ngIf="isAsociado">
      <label class="TxtInput mb-1" id="lblIdentFind">
        Identificación
      </label>
      <input minlength="5" maxlength="20" type="text" name="sNunIdentFind" #sNunIdentFind="ngModel" class="form-control inputAcompañamiento"
        placeholder="Identificacion" required="isAsociado" required="isAsociado" [(ngModel)]="Identi" />
    </div>
    <div class="col-lg-1 col-md-12 col-12 ajustarBoton" *ngIf="isAsociado">
      <button pButton pRipple type="button" icon="pi pi-search" (click)="findAsociado()"
        class="p-button-rounded p-button-success p-button-outlined"></button>
    </div>
  </div>
  <p-tabView styleClass="tabview-custom">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="pi pi-id-card"></i>
        <span>Información Pagaré</span>
      </ng-template>
      <form id="formInfoCodeudor" name="formInfoCodeudor" class="needs-validation" novalidate ngNativeValidate
        #formInfoCodeudor="ngForm" autocomplete="off">
              <div *ngIf="!isAsociado || informacion">
                <div class="p-col-12">
                  <div class="p-grid">
                    <div class="p-col">
                      <label for="tipoPerfil" class="txtForm">Tipo Perfil</label>
                    </div>
                    <div class="p-col">
                        <select id="tipoPerfil" class="form-control tamanio" required="isAsociado"
                          name="tipoPerfil" [disabled]="informacion" [(ngModel)]="tipoPerfil">
                          <option value="{{item.ICodigo}}" *ngFor="let item of listPerfilCiente" class="form-control">
                            {{item.CDescripcion}}
                          </option>
                        </select>
                    </div>
                    <div class="p-col">
                      <h5>
                        <label for="tipoIden" class="txtForm">Tipo identificación</label>
                      </h5>
                    </div>
                    <div class="p-col">
                        <select id="tipoIden" class="form-control tamanio" required="isAsociado"
                          [(ngModel)]="infoPersona.ITipoIdent.ICodigo" name="iTipoIdent" [disabled]="informacion">
                          <option value="{{item.ICodigo}}" *ngFor="let item of listTipoIdent" class="form-control">
                            {{item.CDescripcion}}
                          </option>
                        </select>
                    </div>
                    <div class="p-col">
                      <label class="txtForm">
                        Identificación
                      </label>
                    </div>
                    <div class="p-col">
                        <input type="text" minlength="5" maxlength="20" (keypress)="validarIdentificacion($event)" class="form-control tamanio" placeholder="Identificación"
                          [(ngModel)]="infoPersona.CIdentificacion" name="cIdentificacion" required="isAsociado"
                          [disabled]="informacion" />
                    </div>
                  </div>
                  <div class="p-grid">
                    <div class="p-col">
                        <label class="txtForm">Nombre</label>
                    </div>
                    <div class="p-col">
                        <input type="text" minlength="2" maxlength="30" (keypress)="validarTexto($event)" class="form-control tamanio" pInputText placeholder="Nombre"
                          [(ngModel)]="infoPersona.CNombres" name="cNombres" required="isAsociado" required="isAsociado"
                          [disabled]="informacion" />
                    </div>
                    <div class="p-col">
                      <label class="txtForm">
                        Apellido
                      </label>
                    </div>
                    <div class="p-col">
                        <input type="text" minlength="1" maxlength="30" (keypress)="validarTexto($event)" class="form-control tamanio" placeholder="Apellido"
                          [(ngModel)]="infoPersona.CApellidos" name="cApellidos" required="isAsociado"
                          [disabled]="informacion" />
                    </div>
                    <div class="p-col">
                      <label class="txtForm">
                        Genero
                      </label>
                    </div>
                    <div class="p-col">
                      <select id="cGenero" class="form-control tamanio" required="isAsociado"
                          [(ngModel)]="infoPersona.CGenero" name="cGenero" [disabled]="informacion">
                          <option value="M">Masculino</option>
                          <option value="F">Femenino</option>
                        </select>
                    </div>
                  </div>
                  <div style="margin-bottom: 5px;"></div>
                  <div class="p-grid">
                    <div class="p-col">
                      <label class="txtForm">Dirección</label>
                    </div>
                    <div class="p-col">
                      <input type="text" minlength="5" maxlength="30" class="form-control tamanio" pInputText placeholder="Dirección"
                          [(ngModel)]="infoPersona.CDireccionResidencia" name="cDireccion"
                          required="isAsociado" [disabled]="informacion" />
                    </div>
                    <div class="p-col">
                      <label class="txtForm">
                        Teléfono
                      </label>
                    </div>
                    <div class="p-col">
                      <input type="text" maxlength="7" minlength="7" type="text" (keypress)="validarNumeros($event)" class="form-control tamanio" placeholder="Teléfono"
                      [(ngModel)]="infoPersona.CNumTelFijo" name="cTelefono" [disabled]="informacion"
                      required="isAsociado" />
                    </div>
                    <div class="p-col">
                      <label class="txtForm">Celular</label>
                    </div>
                    <div class="p-col">
                      <input type="text" maxlength="10" minlength="10" (keypress)="validarNumeros($event)" class="form-control tamanio" pInputText placeholder="Celular"
                          [(ngModel)]="infoPersona.CNumCelular" name="cCelular" required="isAsociado" required="isAsociado"
                          [disabled]="informacion" />
                    </div>
                  </div>
                  <div style="margin-bottom: 5px;"></div>
                  <div class="p-grid">
                    <div class="p-col">
                      <label class="txtForm">
                        Email
                      </label>
                    </div>
                    <div class="p-col">
                      <input minlength="7" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" type="text" class="form-control tamanio" placeholder="Email"
                          [(ngModel)]="infoPersona.CEmail" name="cEmail" required="isAsociado" [disabled]="informacion" />
                    </div>
                    <div class="p-col">
                      <label class="txtForm">
                        Ciudad
                      </label>
                    </div>
                    <div class="p-col">
                      <select id="cCiudad" class="form-control tamanio" required="isAsociado"
                      [(ngModel)]="infoPersona.CCiudad.ICodigo" name="cCiudad" [disabled]="informacion">
                          <option value="{{ciudad.iCodigo}}" *ngFor="let ciudad of listaCiudades">
                            {{ciudad.cNombre}}
                          </option>
                        </select>
                    </div>
                    <div class="p-col">
                      <label class="txtForm">
                          N° de Pagaré
                      </label>
                    </div>
                    <div class="p-col">
                      <input type="text" (keypress)="validarNumeros($event)" class="form-control tamanio" pInputText placeholder="N° de Pagaré"
                          [(ngModel)]="numPagare" name="nPagare" required="isAsociado"
                          />
                    </div>
                  </div>
                </div>
              </div>
              <label class="TxtInput">
                ¿El pagaré requiere codeudor?
              </label>
              NO<p-inputSwitch [(ngModel)]="reqCodeudor" (click)="reiniciarFormCodeudores()" name="cReqCodeudor"
                id="cReqCodeudor">
              </p-inputSwitch>SI
      </form>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="pi pi-users"></i>
        <span>Información codeudor(es)</span>
      </ng-template>
      <form id="formInfoCodeudores" name="formInfoCodeudores" class="needs-validation" novalidate ngNativeValidate
      #formInfoCodeudores="ngForm" autocomplete="off">
            <div *ngIf="reqCodeudor">
              <div class="p-col-12">
                <div class="p-grid">
                  <div class="p-col">
                    <label for="tipoPerfil" class="txtForm">Tipo Perfil</label>
                  </div>
                  <div class="p-col">
                      <select [disabled]="codCargado" id="tipoPerfil" class="form-control tamanio" required="isAsociado"
                        name="tipoPerfil" [(ngModel)]="codeudor.TPerfil">
                        <option value="{{item.ICodigo}}" *ngFor="let item of listPerfilCiente" class="form-control">
                          {{item.CDescripcion}}
                        </option>
                      </select>
                  </div>
                  <div class="p-col">
                    <label>
                      <label for="tipoIden" class="txtForm">Tipo identificación</label>
                    </label>
                  </div>
                  <div class="p-col">
                      <select [disabled]="codCargado" id="tipoIden" class="form-control tamanio" required="isAsociado"
                        [(ngModel)]="codeudor.ITipoIdent.ICodigo" name="iTipoIdent">
                        <option value="{{item.ICodigo}}" *ngFor="let item of listTipoIdent" class="form-control">
                          {{item.CDescripcion}}
                        </option>
                      </select>
                  </div>
                  <div class="p-col">
                    <label class="txtForm">
                      Identificación
                    </label>
                  </div>
                  <div class="p-col">
                      <input [disabled]="codCargado" type="text" minlength="5" maxlength="20" (keypress)="validarIdentificacion($event)" class="form-control tamanio" placeholder="Identificación"
                        [(ngModel)]="codeudor.CIdentificacion" name="cIdentificacion" required="isAsociado"
                         />
                  </div>
                </div>
                <div class="p-grid">
                  <div class="p-col">
                      <label class="txtForm">Nombre </label>
                  </div>
                  <div class="p-col">
                      <input [disabled]="codCargado" type="text" minlength="2" maxlength="30" (keypress)="validarTexto($event)" class="form-control tamanio" pInputText placeholder="Nombre"
                        [(ngModel)]="codeudor.CNombres" name="cNombres" required="isAsociado" required="isAsociado"
                         />
                  </div>
                  <div class="p-col">
                    <label class="txtForm">
                      Apellido
                    </label>
                  </div>
                  <div class="p-col">
                      <input [disabled]="codCargado" type="text" minlength="1"  maxlength="30" (keypress)="validarTexto($event)" class="form-control tamanio" placeholder="Apellido"
                        [(ngModel)]="codeudor.CApellidos" name="cApellidos" required="isAsociado"
                         />
                  </div>
                  <div class="p-col">
                    <label class="txtForm">
                      Genero
                    </label>
                  </div>
                  <div class="p-col">
                    <select [disabled]="codCargado" id="cGenero" class="form-control tamanio" required="isAsociado"
                        [(ngModel)]="codeudor.CGenero" name="cGenero" >
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                      </select>
                  </div>
                </div>
                <div style="margin-bottom: 5px;"></div>
                <div class="p-grid">
                  <div class="p-col">
                    <label class="txtForm">Dirección</label>
                  </div>
                  <div class="p-col">
                    <input [disabled]="codCargado" type="text" minlength="5" maxlength="30" class="form-control tamanio" pInputText placeholder="Dirección"
                        [(ngModel)]="codeudor.CDireccionResidencia" name="cDireccion"
                        required="isAsociado"  />
                  </div>
                  <div class="p-col">
                    <label class="txtForm">
                      Teléfono
                    </label>
                  </div>
                  <div class="p-col">
                    <input [disabled]="codCargado" type="text" maxlength="7" minlength="7" type="text" (keypress)="validarNumeros($event)" class="form-control tamanio" placeholder="Teléfono"
                    [(ngModel)]="codeudor.CNumTelFijo" name="cTelefono"
                    required="isAsociado" />
                  </div>
                  <div class="p-col">
                    <label class="txtForm">Celular</label>
                  </div>
                  <div class="p-col">
                    <input [disabled]="codCargado" type="text" maxlength="10" minlength="10" (keypress)="validarNumeros($event)" class="form-control tamanio" pInputText placeholder="Celular"
                        [(ngModel)]="codeudor.CNumCelular" name="cCelular" required="isAsociado" required="isAsociado"
                         />
                  </div>
                </div>
                <div style="margin-bottom: 5px;"></div>
                <div class="p-grid">
                  <div class="p-col">
                    <label class="txtForm">
                      Email
                    </label>
                  </div>
                  <div class="p-col">
                    <input [disabled]="codCargado" minlength="7" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" type="text" class="form-control tamanio" placeholder="Email"
                        [(ngModel)]="codeudor.CEmail" name="cEmail" required="isAsociado" />
                  </div>
                  <div class="p-col">
                    <label class="txtForm">
                      Ciudad
                    </label>
                  </div>
                  <div class="p-col">
                    <select [disabled]="codCargado" id="cCiudad" class="form-control tamanio" required="isAsociado"
                    [(ngModel)]="codeudor.CCiudad" name="cCiudad">
                        <option value="{{ciudad.iCodigo}}" *ngFor="let ciudad of listaCiudades">
                          {{ciudad.cNombre}}
                        </option>
                      </select>
                  </div>
                  <div class="p-col">
                    <label class="txtForm">
                    </label>
                  </div>
                  <div class="p-col">
                    <label for="" class="tamanio">
                      <button (click)="validarCamposCodeudor()" class="btn btn-primary ampliar"> Agregar Codeudor </button>
                    </label>
                  </div>
                </div>
              </div>
            </div>
    </form>
    </p-tabPanel>
  </p-tabView>
  <div class="separador"></div>
  <ng-template pTemplate="footer">
    <button type="button" pButton pRipple icon="pi pi-times" iconPos="left" label="Cancelar"
      class="p-button-raised p-button-warning p-mr-4" style="color: white;" (click)="cerrarModal()"></button>
    <button type="submit" pButton pRipple icon="pi pi-check" iconPos="left" label="Guardar"
      class="p-button-raised p-button-primary p-mr-4" (click)="validarCampos()"></button>
  </ng-template>
</p-dialog>

