import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { GestorSolPagareImpService } from 'src/app/services/ImplementacionServicios/gestor-sol-pagare-imp.service';
import { RespuestaGenerica } from '../../../Model/Response/RespuestaGenerica';
import { DialogComponent } from '../../pages/dialog/dialog.component';
import {
  ResponseDetalleFirmaPagare,
  firmas,
} from 'src/app/Model/Response/response-detalle-firma-pagare';
import { CargarTodosUsuariosImpService } from '../../../services/ImplementacionServicios/cargar-todos-usuarios-imp';
import { MessageService } from 'primeng/api';
import { PersonaCodeudor, PersonaRe,CCiudad } from '../../../Model/DTO/PersonaRegistry';
import { cCiudad, iTipoIde } from '../../../Model/Response/RespuestaPersona';
import { ResponseSolicitudPagare } from '../../../Model/Response/response-solicitud-pagare';
import { CargaDatosImpService } from 'src/app/services/ImplementacionServicios/carga-datos-imp';
import {
  RespuestaTipoPerfil,
  TipoPerfil,
} from 'src/app/Model/Response/RespuestaTipoPerfil';
import {
  RespuestaTipoIdent,
  TipoIdentificacion,
} from 'src/app/Model/Response/RespuestaTipoIdent';
import { SolicitudPagare } from 'src/app/Model/solicitud-pagare';
import { ResponseObtenerDocumento, ResponseObtenerMultiplesDocs } from 'src/app/Model/Response/response-obtener-documento';
import { ElementRef } from '@angular/core';
import {
  Ciudad,
  RespuestaCiudad,
} from 'src/app/Model/Response/response-ciudades';
import { TokenImpService } from 'src/app/Services/ImplementacionServicios/token-imp';
import { SeguridadClienteService } from 'src/app/Services/ConsumoServicios/seguridad-cliente.service';
import { RequestCrearPagare } from 'src/app/Model/Request/request-crear-pagare';
import { DataRowOutlet } from '@angular/cdk/table';
@Component({
  selector: 'app-gestion-pagare',
  templateUrl: './gestion-pagare.component.html',
  styleUrls: ['./gestion-pagare.component.css'],
  providers: [MessageService],
})
export class GestionPagareComponent implements OnInit {
  constructor(
    private gestorSolPagareImpServie: GestorSolPagareImpService,
    private SpinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private cargarTodosUsuariosImpService: CargarTodosUsuariosImpService,
    private cargaDatosServiceImp: CargaDatosImpService,
    private elRef: ElementRef,
    private tokenServiceImp: TokenImpService,
    private seguridadService: SeguridadClienteService
  ) {}
  public verificacion: boolean;
  public dataSource: ResponseSolicitudPagare[];
  public isAsociado: boolean;
  public listaCiudades: Ciudad[];
  public infoPersona: PersonaRe = new PersonaRe();
  public displayModalInfoCodeudor: boolean;
  public displayModalFirmas: boolean;
  public informacion: boolean;
  public listFirmas: firmas[];
  public datosPersona: any;
  public tipoIndent: any;
  public tipoPerfil: number;
  public tipoPerfilCod: number;
  public tipoPerfilFind: number;
  public codeudores: any[] = [];
  public Identi: any;
  public cCiudad: number;
  public ciudadDeu: CCiudad;
  public cCiudadCod: number;
  public codeudor: PersonaCodeudor = new PersonaCodeudor();
  public reqCodeudor: boolean;
  public ITipoIden: iTipoIde;
  public ciudad: cCiudad;
  public listPerfilCiente: TipoPerfil[];
  public listTipoIdent: TipoIdentificacion[];
  public numPagare: number;
  public codeudorAgregado: boolean;
  public codCargado: boolean;
  ngOnInit() {
    this.codCargado = false;
    this.verificacion = false;
    this.codeudores = [];
    this.displayModalInfoCodeudor = false;
    this.isAsociado = false;
    this.informacion = false;
    this.reqCodeudor = true;
    this.listPerfilCiente = null;
    this.listTipoIdent = null;
    this.tipoPerfilFind = null;
    this.tipoPerfil = null;
    this.ITipoIden = { ICodigo: 0, CDescripcion: '', CEstado: '' };
    this.ciudad = { ICodigo: 0, CNombres: '' };
    this.ciudadDeu = { ICodigo: 0, CNombres: '' };
    this.infoPersona = {
      ICodigo: null,
      CIdentificacion: null,
      CNombres: null,
      CApellidos: null,
      CDireccionResidencia: null,
      CNumTelFijo: null,
      CNumCelular: null,
      CEmail: null,
      CCiudad: this.ciudadDeu,
      CGenero: null,
      CEstado: null,
      ITipoIdent: this.ITipoIden,
    };
    this.codeudor = {
      ICodigo: null,
      CIdentificacion: null,
      CNombres: null,
      CApellidos: null,
      CDireccionResidencia: null,
      CNumTelFijo: null,
      CNumCelular: null,
      CEmail: null,
      CGenero: null,
      CEstado: null,
      ITipoIdent: this.ITipoIden,
    };
    this.cargarTableSolPagare();
  }

  reiniciarFormCodeudores() {
    if (this.reqCodeudor === false) {
      this.codeudores = [];
      this.ITipoIden = { ICodigo: 0, CDescripcion: '', CEstado: '' };
      this.tipoPerfilCod = 0;
      this.cCiudadCod = 0;
      this.codeudor = {
        ICodigo: null,
        CIdentificacion: null,
        CNombres: null,
        CApellidos: null,
        CDireccionResidencia: null,
        CNumTelFijo: null,
        CNumCelular: null,
        CEmail: null,
        CGenero: null,
        CEstado: null,
        ITipoIdent: this.ITipoIden,
      };
    } else {
      this.messageService.add({
        key: 'msgDiaglogValident',
        severity: 'warn',
        summary: 'Alerta!',
        detail: 'Solo podrá agregar máximo 1 codeudores',
      });
    }
  }

  ReenviarOTP(xICodigo: number) {
    this.SpinnerService.show('sp6');
    let requestSolPagare: SolicitudPagare;
    requestSolPagare = { ICodigo: xICodigo };
    this.gestorSolPagareImpServie
      .reenviarOTPSolPagare(requestSolPagare)
      .then((data: RespuestaGenerica) => {
        if (data != null && data.bRta != null && data.bRta) {
          this.messageService.add({
            key: 'msgToast',
            severity: 'success',
            summary: '¡Exito!',
            detail: data.sMsj,
            life: 10000,
          });
          this.SpinnerService.hide('sp6');
        } else {
          this.SpinnerService.hide('sp6');
          this.messageService.add({
            key: 'msgToast',
            severity: 'error',
            summary: '¡Alerta!',
            detail: data.sMsj,
            life: 10000,
          });
        }
      })
      .catch((error) => {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'error',
          summary: '¡Alerta!',
          detail:
            'Hubo un error al obtener el documento, intente de nuevo más tarde',
          life: 10000,
        });
      });
  }

  cargarTableSolPagare() {
    this.SpinnerService.show('sp6');
    this.gestorSolPagareImpServie
      .cargarSolicitudesPagare()
      .then((response: any) => {
        if (
          response !== null &&
          response !== undefined &&
          response.length > 0
        ) {
          this.dataSource = response;
          this.SpinnerService.hide('sp6');
          let tablaResponsive = this.elRef.nativeElement.querySelector(
            '.p-datatable-responsive-demo'
          );
          tablaResponsive.classList.remove('p-datatable-responsive-demo');
          tablaResponsive.classList.add('p-datatable-responsive');
        }
        else if (response === undefined || response == null || response == 0 || response == '') {
          this.SpinnerService.hide('sp6');
          this.messageService.add({
            key: 'msgToast',
            severity: 'warn',
            summary: 'Alerta!',
            detail:
              'No hay solicitudes para cargar',
          });
        }
        else {
          this.SpinnerService.hide('sp6');
          this.messageService.add({
            key: 'msgToast',
            severity: 'error',
            summary: '¡Error!',
            detail:
              'Ha ocurrido un error al intentar obtener la información, intente de nuevo',
          });
        }
      });
  }

  obtenerCiudades() {
    this.SpinnerService.show('sp6');
    this.cargaDatosServiceImp
      .obtenerCiudades()
      .then((res: RespuestaCiudad) => {
        if (res != null && res.flag) {
          this.listaCiudades = res.listaCiudad;
          this.SpinnerService.hide('sp6');
        } else {
          this.SpinnerService.hide('sp6');
          this.messageService.add({
            key: 'msgToast',
            severity: 'error',
            summary: '¡Error!',
            detail:
              'Ha ocurrido un error al intentar obtener la información, intente de nuevo',
          });
        }
      })
      .catch((error) => {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'error',
          summary: '¡Error!',
          detail: 'Ha ocurrido un error de conexión, intente de nuevo',
        });
      });
  }

  obtenerPerfilesClientes() {
    this.SpinnerService.show('sp6');
    this.cargaDatosServiceImp
      .tipoPerfilCliente()
      .then((response: RespuestaTipoPerfil) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide('sp6');
          this.listPerfilCiente = response.listaTipoPerfil;
        } else {
          this.SpinnerService.hide('sp6');
          this.messageService.add({
            key: 'msgToast',
            severity: 'error',
            summary: '¡Error!',
            detail:
              'Ha ocurrido un error al intentar obtener la información, intente de nuevo',
          });
        }
      }),
      (error) => {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'error',
          summary: '¡Error!',
          detail: 'Ha ocurrido un error de conexión, intente de nuevo',
        });
      };
  }

  obtenerTiposIdent() {
    this.SpinnerService.show('sp6');
    this.cargaDatosServiceImp
      .tiposIden()
      .then((response: RespuestaTipoIdent) => {
        if (response != null && response.flag) {
          this.SpinnerService.hide('sp6');
          this.listTipoIdent = response.listaTipoIdent;
        } else {
          this.SpinnerService.hide('sp6');
          this.messageService.add({
            key: 'msgToast',
            severity: 'error',
            summary: '¡Error!',
            detail:
              'Ha ocurrido un error al intentar obtener la información, intente de nuevo',
          });
        }
      })
      .catch((error) => {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'error',
          summary: '¡Error!',
          detail: 'Ha ocurrido un error de conexión, intente de nuevo',
        });
      });
  }

  actualizarEstado(xiIdProceso: string) {
    this.SpinnerService.show('sp6');
    this.gestorSolPagareImpServie
      .cargarEstadoPagare(xiIdProceso)
      .then((data: RespuestaGenerica) => {
        this.SpinnerService.hide('sp6');
        if (data.bRta === false) {
          this.messageService.add({
            key: 'msgToast',
            severity: 'warn',
            summary: 'Alerta!',
            detail: data.sMsj,
          });
        } else {
          this.messageService.add({
            key: 'msgToast',
            severity: 'success',
            summary: '¡Éxito!',
            detail: data.sMsj,
          });
          this.cargarTableSolPagare();
        }
      })
      .catch((error) => {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'error',
          summary: '¡Error!',
          detail: 'Ha ocurrido un error de conexión, intente de nuevo',
        });
      });
  }

  detalleFirmasPagare(xiIdProceso: string) {
    this.SpinnerService.show('sp6');
    this.gestorSolPagareImpServie
      .cargarDetalleFirmaDocumento(xiIdProceso)
      .then((data: ResponseDetalleFirmaPagare) => {
        this.SpinnerService.hide('sp6');
        if (data.bRta === false) {
          this.messageService.add({
            key: 'msgToast',
            severity: 'warn',
            summary: 'Alerta!',
            detail: data.sMsg,
          });
        } else {
          this.messageService.add({
            key: 'msgToast',
            severity: 'success',
            summary: '¡Éxito!',
            detail: data.sMsg,
          });
          this.displayModalFirmas = true;
          this.listFirmas = data.firmas;
        }
      })
      .catch((error) => {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'error',
          summary: 'error!',
          detail:
            'Hubo un error al obtener las firmas, intente de nuevo más tarde',
        });
      });
  }

  obtenerMultiplesDocs(xICodigo: number, sNumRadic: string){
    this.SpinnerService.show('sp6');
    let requestSolPagare: SolicitudPagare;
    requestSolPagare = { ICodigo: xICodigo };
    this.gestorSolPagareImpServie.obtenerMultiplesDocs(requestSolPagare).then((data: ResponseObtenerMultiplesDocs) => {
      if (
        data != null &&
        data != 'ERROR' &&
        data.respuesta.mensaje != null &&
        data.respuesta.codigo == 1
      ) {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'success',
          summary: '¡Exito!',
          detail: data.respuesta.mensaje,
        });
        for (const documento of data.documentos) {
          let link = document.createElement('a');
          link.download = `Documento${documento.nombre}` + sNumRadic + '.pdf';
          link.href = 'data:application/octet-stream;base64,' + documento.base64;
          link.click();
        }
      } else {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'error',
          summary: '¡Alerta!',
          detail: data.respuesta.mensaje,
        });
      }
    });
  }

  ObtenerDocumentoPagare(xICodigo: number, sNumRadic: string) {
    this.SpinnerService.show('sp6');
    let requestSolPagare: SolicitudPagare;
    requestSolPagare = { ICodigo: xICodigo };
    this.gestorSolPagareImpServie
      .obtenerDocumentoPagare(requestSolPagare)
      .then((data: ResponseObtenerDocumento) => {
        if (
          data != null &&
          data != 'ERROR' &&
          data.respuestaBase != null &&
          data.respuestaBase.codigo == 1
        ) {
          this.messageService.add({
            key: 'msgToast',
            severity: 'success',
            summary: '¡Exito!',
            detail: data.respuestaBase.mensaje,
          });
          var link = document.createElement('a');
          link.download = 'Pagare_' + sNumRadic + '.pdf';
          link.href =
            'data:application/octet-stream;base64,' + data.archivoBase64;
          link.click();
          this.SpinnerService.hide('sp6');
        } else {
          this.SpinnerService.hide('sp6');
          this.messageService.add({
            key: 'msgToast',
            severity: 'error',
            summary: '¡Alerta!',
            detail: data.respuestaBase.mensaje,
          });
        }
      })
      .catch((error) => {
        this.SpinnerService.hide('sp6');
        this.messageService.add({
          key: 'msgToast',
          severity: 'error',
          summary: '¡Alerta!',
          detail:
            'Hubo un error al obtener el documento, intente de nuevo más tarde',
        });
      });
  }

  abrirDialog(
    xTitulo: string,
    xMensaje: string,
    tipoDialog: string,
    res: boolean
  ) {
    const dataC = {
      titulo: xTitulo,
      texto: xMensaje,
      tipoDialog,
      res,
    };
    const dialogRef = this.dialog.open(DialogComponent, {
      data: dataC,
      panelClass: 'prueba',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (dataC.res === true) {
      }
    });
  }

  crearPagares(personaDeudor: PersonaRe) {
    this.SpinnerService.show('sp6');
    personaDeudor.CNombres = personaDeudor.CNombres.toUpperCase();
    personaDeudor.CApellidos = personaDeudor.CApellidos.toUpperCase();
    personaDeudor.CDireccionResidencia = personaDeudor.CDireccionResidencia.toUpperCase();
    let requestCreaSolPagare: RequestCrearPagare = new RequestCrearPagare();
    requestCreaSolPagare.iTipoPerfilDeudor = this.tipoPerfil;
    requestCreaSolPagare.requiereCodeudor = this.reqCodeudor;
    requestCreaSolPagare.iTipoPerfilCodeudor = this.codeudor.TPerfil;
    requestCreaSolPagare.personaDeudor = personaDeudor;
    requestCreaSolPagare.numPagare = this.numPagare;

    console.log("/////////////////////////")

    const jsonPersona = JSON.stringify(this.infoPersona);
    this.cargarTodosUsuariosImpService.registrarUsuario(jsonPersona, this.tipoPerfil, personaDeudor.CCiudad.ICodigo).then(
      (res: any) => {
        console.log ("solicitud pagare"+""+jsonPersona);
        if (res.bRta) {
          console.log ("Respuesta"+""+res.bRta);
          this.messageService.add({key: 'msgDiaglogValident', severity: 'success', summary: '¡Excelente!', detail: res.sMsj});
          this.gestorSolPagareImpServie
          .saveSolicitudPagare(requestCreaSolPagare)
          .then((data: RespuestaGenerica) => {
            if (data.bRta) {
              this.SpinnerService.hide('sp6');
              this.cerrarModal();
              this.messageService.add({
                key: 'msgToast',
                severity: 'success',
                summary: 'Excelente!',
                detail: data.sMsj,
                life: 10000,
              });
              this.messageService.add({
                key: 'msgDiaglogValident',
                severity: 'success',
                summary: 'Excelente!',
                detail: data.sMsj,
                life: 10000,
              });
              this.cargarTableSolPagare();
            } else {
              this.SpinnerService.hide('sp6');
              this.messageService.add({
                key: 'msgDiaglogValident',
                severity: 'error',
                summary: '¡Alerta!',
                detail: data.sMsj,
                life: 10000,
              });
            }
          });
        }
        else if(res.sMsj === "Se encontro registro de la persona con el perfil, tipo identificación y número de identificación ingresado, por favor verifique la información.") {
          this.gestorSolPagareImpServie
            .saveSolicitudPagare(requestCreaSolPagare)
            .then((data: RespuestaGenerica) => {
              if (data.bRta) {
                this.SpinnerService.hide('sp6');
                this.cerrarModal();
                this.messageService.add({
                  key: 'msgToast',
                  severity: 'success',
                  summary: 'Excelente!',
                  detail: data.sMsj,
                  life: 10000,
                });
                this.messageService.add({
                  key: 'msgDiaglogValident',
                  severity: 'success',
                  summary: 'Excelente!',
                  detail: data.sMsj,
                  life: 10000,
                });
                this.cargarTableSolPagare();
              } else {
                this.SpinnerService.hide('sp6');
                this.messageService.add({
                  key: 'msgDiaglogValident',
                  severity: 'error',
                  summary: '¡Alerta!',
                  detail: data.sMsj,
                  life: 10000,
                });
              }
            });
        }

      }
    );
  }



  showModalNewInfoCodeudor() {
    this.findCodeudor();
    this.tipoPerfilFind = null;
    this.tipoPerfil = null;
    this.listPerfilCiente = null;
    this.listTipoIdent = null;
    this.isAsociado = false;
    this.informacion = false;
    this.reqCodeudor = true;
    this.numPagare = null;
    this.ITipoIden = { ICodigo: 0, CDescripcion: '', CEstado: '' };
    this.ciudad = { ICodigo: null, CNombres: null };
    this.ciudadDeu = { ICodigo: 0, CNombres: '' };
    this.listaCiudades = [];
    this.cCiudad = 0;
    this.infoPersona = {
      ICodigo: null,
      CIdentificacion: null,
      CNombres: null,
      CApellidos: null,
      CDireccionResidencia: null,
      CNumTelFijo: null,
      CNumCelular: null,
      CEmail: null,
      CCiudad: this.ciudadDeu,
      CGenero: null,
      CEstado: null,
      ITipoIdent: this.ITipoIden,
    };
    this.obtenerPerfilesClientes();
    this.obtenerCiudades();
    this.obtenerTiposIdent();
    this.displayModalInfoCodeudor = true;
  }

  cerrarModal() {
    this.tipoPerfilFind = null;
    this.tipoPerfil = null;
    this.numPagare = null;
    this.listPerfilCiente = null;
    this.listTipoIdent = null;
    this.isAsociado = false;
    this.informacion = false;
    this.codCargado = false;
    this.codeudores = [];
    this.reqCodeudor = false;
    this.ITipoIden = { ICodigo: 0, CDescripcion: '', CEstado: '' };
    this.ciudad = { ICodigo: null, CNombres: null };
    this.ciudadDeu = { ICodigo: 0, CNombres: '' };
    this.listaCiudades = [];
    this.cCiudad = 0;
    let form = this.elRef.nativeElement.querySelector('.needs-validation');
    form.classList.remove('was-validated');
    this.infoPersona = {
      ICodigo: null,
      CIdentificacion: null,
      CNombres: null,
      CApellidos: null,
      CDireccionResidencia: null,
      CNumTelFijo: null,
      CNumCelular: null,
      CEmail: null,
      CCiudad: this.ciudadDeu,
      CGenero: null,
      CEstado: null,
      ITipoIdent: this.ITipoIden,
    };
    this.displayModalInfoCodeudor = false;
    this.messageService.clear();
  }

  reiniciarCampos() {
    this.tipoPerfilFind = null;
    this.tipoPerfil = null;
    this.listPerfilCiente = null;
    this.listTipoIdent = null;
    this.numPagare = null;
    this.informacion = false;
    this.tipoIndent = null;
    this.Identi = null;
    this.ITipoIden = { ICodigo: 0, CDescripcion: '', CEstado: '' };
    this.ciudad = { ICodigo: null, CNombres: null };
    this.ciudadDeu = { ICodigo: 0, CNombres: '' };
    this.listaCiudades = [];
    this.cCiudad = 0;
    let forms = this.elRef.nativeElement.querySelector('.needs-validation');
    forms.classList.remove('was-validated');
    this.infoPersona = {
      ICodigo: null,
      CIdentificacion: null,
      CNombres: null,
      CApellidos: null,
      CDireccionResidencia: null,
      CNumTelFijo: null,
      CNumCelular: null,
      CEmail: null,
      CCiudad: this.ciudadDeu,
      CGenero: null,
      CEstado: null,
      ITipoIdent: this.ITipoIden,
    };
    this.obtenerPerfilesClientes();
    this.obtenerCiudades();
    this.obtenerTiposIdent();
  }

  findCodeudor() {
    let identCod = '900710515';
    let perfilCod = 3;
    let tipoIdentCod = '4';
    this.SpinnerService.show('sp6');
    this.messageService.clear();
      this.cargarTodosUsuariosImpService
        .BuscarPersonaByIdentAndPerfil(
          perfilCod,
          btoa(tipoIdentCod),
          btoa(identCod)
        )
        .then((data: any) => {
          this.SpinnerService.hide('sp6');
          if (data != null && data.bRta) {
            this.codCargado = true;
            this.messageService.add({
              key: 'msgDiaglogValident',
              severity: 'success',
              summary: '¡Excelente!',
              detail: 'Se ha cargado el codeudor FIANZATE S.A',
            });
            this.codeudor.TPerfil = perfilCod;
            this.codeudor.CCiudad = data.persona.cCiudad.iCodigo;
            this.codeudor.CNombres = data.persona.CNombres;
            this.codeudor.CApellidos = data.persona.CApellidos;
            this.codeudor.ITipoIdent.ICodigo = data.persona.ITipoIdent.ICodigo;
            this.codeudor.CIdentificacion = data.persona.CIdentificacion;
            this.codeudor.CNumTelFijo = data.persona.CNumTelFijo;
            this.codeudor.CNumCelular = data.persona.CNumCelular;
            this.codeudor.CEmail = data.persona.CEmail;
            this.codeudor.CGenero = data.persona.CGenero;
            this.codeudor.CDireccionResidencia = data.persona.CDireccionResidencia;
          } else {
            this.SpinnerService.hide('sp6');
            this.messageService.add({
              key: 'msgDiaglogValident',
              severity: 'error',
              summary: '¡Alerta!',
              detail: data.sMsj,
            });
          }
        });
  }

  findAsociado() {
    this.SpinnerService.show('sp6');
    this.messageService.clear();
    if (
      this.tipoPerfilFind !== null &&
      this.tipoPerfilFind !== undefined &&
      this.tipoIndent !== null &&
      this.tipoIndent !== undefined &&
      this.Identi !== null &&
      this.Identi !== undefined
    ) {
      this.cargarTodosUsuariosImpService
        .BuscarPersonaByIdentAndPerfil(
          this.tipoPerfilFind,
          btoa(this.tipoIndent),
          btoa(this.Identi)
        )
        .then((data: any) => {
          this.SpinnerService.hide('sp6');
          if (data != null && data.bRta) {
            this.informacion = true;
            this.messageService.add({
              key: 'msgDiaglogValident',
              severity: 'success',
              summary: '¡Excelente!',
              detail: data.sMsj,
            });
            this.datosPersona = data.persona;
            this.tipoPerfil = this.tipoPerfilFind;
            this.infoPersona.CCiudad.ICodigo = this.datosPersona.cCiudad.iCodigo;
            this.infoPersona.CNombres = this.datosPersona.CNombres;
            this.infoPersona.CApellidos = this.datosPersona.CApellidos;
            this.infoPersona.ITipoIdent.ICodigo = this.datosPersona.ITipoIdent.ICodigo;
            this.infoPersona.CIdentificacion = this.datosPersona.CIdentificacion;
            this.infoPersona.CNumTelFijo = this.datosPersona.CNumTelFijo;
            this.infoPersona.CNumCelular = this.datosPersona.CNumCelular;
            this.infoPersona.CEmail = this.datosPersona.CEmail;
            this.infoPersona.CGenero = this.datosPersona.CGenero;
            this.infoPersona.CDireccionResidencia = this.datosPersona.CDireccionResidencia;
          } else {
            this.SpinnerService.hide('sp6');
            this.messageService.add({
              key: 'msgDiaglogValident',
              severity: 'error',
              summary: '¡Alerta!',
              detail: data.sMsj,
            });
          }
        });
    } else {
      this.SpinnerService.hide('sp6');
      this.messageService.add({
        key: 'msgDiaglogValident',
        severity: 'error',
        summary: '¡Alerta!',
        detail:
          'Por favor seleccione el tipo de identificación e ingrese el número de identificación a buscar como asociado.',
      });
    }
  }

  validarTexto(event: KeyboardEvent) {
    const pattern = /[a-zA-ZñÑ ]/;
    if (pattern.test(event.key)) {
      return true;
    }
    return false;
  }

  validarNumeros(event: KeyboardEvent) {
    const pattern = /^[0-9]/;
    if (pattern.test(event.key)) {
      return true;
    }
    return false;
  }

  validarIdentificacion(event: KeyboardEvent) {
    const pattern = /^[a-zA-Z0-9]+/;
    if (pattern.test(event.key)) {
      return true;
    }
    return false;
  }

  validarCorreo(correo): boolean {
    let pattern = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
    if (correo.match(pattern)) {
      return true;
    } else {
      this.messageService.add({
        key: 'msgDiaglogValident',
        severity: 'error',
        summary: '¡Alerta!',
        detail: 'El email no es valido',
      });
      return false;
    }
  }

  validarCampos() {
    if (
      this.tipoPerfil !== null &&
      this.cCiudad !== null &&
      this.cCiudad !== undefined &&
      this.tipoPerfil !== undefined &&
      this.infoPersona.CNombres !== null &&
      this.infoPersona.CNombres.length > 3 &&
      this.infoPersona.CNombres.length !== '' &&
      this.infoPersona.CNombres !== undefined &&
      this.infoPersona.CApellidos !== null &&
      this.infoPersona.CApellidos !== '' &&
      this.infoPersona.CApellidos !== undefined &&
      this.infoPersona.CApellidos.length > 3 &&
      this.infoPersona.ITipoIdent.ICodigo !== undefined &&
      this.infoPersona.CIdentificacion !== null &&
      this.infoPersona.CIdentificacion !== undefined &&
      this.infoPersona.CIdentificacion.length > 5 &&
      this.infoPersona.CNumTelFijo !== undefined &&
      this.infoPersona.CNumTelFijo !== null &&
      this.infoPersona.CNumTelFijo.length === 7 &&
      this.infoPersona.CNumCelular !== undefined &&
      this.infoPersona.CNumCelular !== undefined &&
      this.infoPersona.CNumCelular.length === 10 &&
      this.infoPersona.CEmail !== null &&
      this.infoPersona.CEmail !== undefined &&
      this.infoPersona.CGenero !== undefined &&
      this.validarCorreo(this.infoPersona.CEmail) &&
      this.numPagare !== undefined &&
      this.numPagare !== null &&
      this.infoPersona.CGenero !== null &&
      this.infoPersona.CDireccionResidencia !== undefined &&
      this.infoPersona.CDireccionResidencia !== null &&
      this.infoPersona.CDireccionResidencia.length > 5
    ) {
      if (this.reqCodeudor) {
        this.crearPagares(this.infoPersona);
      }
    } else if (this.isAsociado === true && this.informacion === false) {
      this.messageService.add({
        key: 'msgDiaglogValident',
        severity: 'error',
        summary: '¡Alerta!',
        detail: 'Debe buscar el usuario para poder guardar o agregué uno nuevo',
      });
    } else {
      let form = this.elRef.nativeElement.querySelector('#formInfoCodeudor');
      form.classList.add('was-validated');
      this.messageService.add({
        key: 'msgDiaglogValident',
        severity: 'error',
        summary: '¡Alerta!',
        detail: 'Verifique los campos para continuar',
      });
    }
  }

  // validarCamposCodeudor() {
  //   if (
  //     this.codeudor.TPerfil !== null &&
  //     this.codeudor.CCiudad !== null &&
  //     this.codeudor.CCiudad !== undefined &&
  //     this.codeudor.TPerfil !== undefined &&
  //     this.codeudor.CNombres !== null &&
  //     this.codeudor.CNombres != '' &&
  //     this.codeudor.CNombres !== undefined &&
  //     this.codeudor.CNombres.length > 3 &&
  //     this.codeudor.CApellidos !== null &&
  //     this.codeudor.CApellidos != '' &&
  //     this.codeudor.CApellidos !== undefined &&
  //     this.codeudor.CApellidos.length > 3 &&
  //     this.codeudor.ITipoIdent.ICodigo !== undefined &&
  //     this.codeudor.CIdentificacion !== null &&
  //     this.codeudor.CIdentificacion &&
  //     this.codeudor.CIdentificacion.length > 5 &&
  //     this.codeudor.CNumTelFijo !== undefined &&
  //     this.codeudor.CNumTelFijo !== null &&
  //     this.codeudor.CNumTelFijo.length === 7 &&
  //     this.codeudor.CNumCelular !== undefined &&
  //     this.codeudor.CNumCelular !== undefined &&
  //     this.codeudor.CNumCelular.length === 10 &&
  //     this.codeudor.CEmail !== null &&
  //     this.codeudor.CEmail !== undefined &&
  //     this.codeudor.CGenero !== undefined &&
  //     this.validarCorreo(this.codeudor.CEmail) &&
  //     this.codeudor.CGenero !== null &&
  //     this.codeudor.CDireccionResidencia !== undefined &&
  //     this.codeudor.CDireccionResidencia !== null &&
  //     this.codeudor.CDireccionResidencia.length > 5
  //   ) {
  //     // this.agregarCodeudor();
  //     return true;
  //   } else {
  //     let form = this.elRef.nativeElement.querySelector('#formInfoCodeudores');
  //     form.classList.add('was-validated');
  //     this.messageService.add({
  //       key: 'msgDiaglogValident',
  //       severity: 'error',
  //       summary: '¡Alerta!',
  //       detail: 'Complete los campos marcados en rojo para continuar',
  //     });
  //     return false;
  //   }
  // }
  validarCamposCodeudor() {
    if (
      this.codeudor.TPerfil !== null && this.codeudor.CCiudad !== null && this.codeudor.CCiudad !== undefined
      && this.codeudor.TPerfil !== undefined && this.codeudor.CNombres !== null && this.codeudor.CNombres !== undefined && this.codeudor.CNombres.length > 3
      && this.codeudor.CApellidos !== null && this.codeudor.CApellidos !== undefined && this.codeudor.CApellidos.length > 3 && this.codeudor.ITipoIdent.ICodigo !== undefined
      && this.codeudor.CIdentificacion !== null && this.codeudor.CIdentificacion && this.codeudor.CIdentificacion.length > 5 && this.codeudor.CNumTelFijo !== undefined
      && this.codeudor.CNumTelFijo !== null && this.codeudor.CNumTelFijo.length === 7 && this.codeudor.CNumCelular !== undefined && this.codeudor.CNumCelular !== undefined && this.codeudor.CNumCelular.length === 10
      && this.codeudor.CEmail !== null && this.codeudor.CEmail !== undefined && this.codeudor.CGenero !== undefined && this.validarCorreo(this.codeudor.CEmail)
      && this.codeudor.CGenero !== null && this.codeudor.CDireccionResidencia !== undefined && this.codeudor.CDireccionResidencia !== null && this.codeudor.CDireccionResidencia.length > 5

    )
  {}
  }
}
