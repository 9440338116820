// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // URL_SERVICE: 'http://150.136.57.80:8081/WSRestAPIFecyr/EBSFECYR/',
  // URL_SERVICE:'http://localhost:9090/WSRestAPIPinc/EBSPINC/',
    URL_SERVICE: 'https://fecyrbe.ebscreditodigital.com/WSRestAPIFecyr/EBSFECYR/',
  // URL_SERVICE: 'http://localhost:9090/WSRestAPICoounimayorcun/EBSCOOUNIMAYORCUN/',

  // URL_SERVICE: 'http://localhost:9090/WSRestAPIProgresemos/EBSPROGRESEMOS/',
  // URL_SERVICE: 'http://localhost:9090/WSRestAPICoounimayorcun/EBSCOOUNIMAYORCUN/',

  // URL_SERVICE: 'http://localhost:9090/WSRestAPIProgresemos/EBSPROGRESEMOS/',
  // local
  // URL_SERVICE: 'http://localhost:9091/WSRestAPIFecyr/EBSFECYR/',

  //URL_SERVICE: 'http://localhost:8080/WSRestAPIFecyr/EBSFECYR/',
   URL_FTP: 'http://appswebpre.ebscreditodigital.com/FTP_PINC/FTP_REDES/',
//URL

  // URL_FTP: 'http://localhost:9090/WSRestAPIProgresemos/',


  // URL_SERVICE:'http://localhost:8580/PINC/APIREST/apirest_pinc/WSRestAPIPinc/WSRestAPIPinc/src/java/com/co/ebssas/pinc/wsrestapi/WSRestCargaDatos.java/',
  // URL_FTP: 'http://localhost:8580/PINC/APIREST/apirest_pinc/WSRestAPIPinc/WSRestAPIPinc/src/java/com/co/ebssas/pinc/wsrestapi/WSRestCargaDatos.java/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
