import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { SolicitudPagare } from '../../Model/DTO/solicitud-pagare';
import { RespuestaProcesoPagare } from "../../Model/Response/RespuestaProcesoPagare";
import { RespuestaEstadoDocumento } from "../../Model/Response/RespuestaEstadoDocumento";
import { ResponseSolicitudPagare } from "../../Model/Response/response-solicitud-pagare";

@Injectable({
  providedIn: 'root'
})
export class PagaredigitalService {
  public url = environment.URL_SERVICE + "/ClienteFecyr";
  public headers = new HttpHeaders(
    {
      "Content-Type": "application/json; charset=UTF-8"
      // "Authorization": "Bearer" + sessionStorage.getItem("token")
    }
  );
  constructor(private http: HttpClient) { }


  generarPagare(solicitudPagare: SolicitudPagare) {
    let path = this.url + "/generarPagareDigital";
    return this.http.post<RespuestaProcesoPagare>(path, solicitudPagare, { headers: this.headers });
  }

  estadoPagare(xiIdProceso: string) {
    let path = this.url + "/verificarEstadoFirmaDocumento";
    path += "?xiIdProceso=" + xiIdProceso;
    return this.http.post<RespuestaEstadoDocumento>(path, { headers: this.headers });
  }

  actualizarEstadoPagare(solicitudPagare: SolicitudPagare) {
    let path = this.url + "/actualizarEstadoPagare";
    return this.http.post<RespuestaEstadoDocumento>(path, solicitudPagare, { headers: this.headers });
  }

  obtenerMisPagares(solicitudPagare: SolicitudPagare) {
    let path = this.url + "/obtenerSolicitudesPagareById";
    return this.http.post<ResponseSolicitudPagare>(path, solicitudPagare, { headers: this.headers });
  }

}
