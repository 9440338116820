export class PersonaRe {
  ICodigo?: any;
  CIdentificacion?: any;
  CNombres?: any;
  CApellidos?: any;
  CDireccionResidencia?: any;
  CNumTelFijo?: any;
  CNumCelular?: any;
  CEmail?: any;
  CCiudad?: CCiudad;
  CGenero?: any;
  CEstado?: any;
  ITipoIdent?: ITipoIde;
}

export class ITipoIde {
  ICodigo?: any;
  CDescripcion?: any;
  CEstado?: any;
}

export class PersonaCodeudor {
  ICodigo?: any;
  CIdentificacion?: any;
  CNombres?: any;
  CApellidos?: any;
  CDireccionResidencia?: any;
  CNumTelFijo?: any;
  CNumCelular?: any;
  CEmail?: any;
  CGenero?: any;
  CCiudad?: any;
  TPerfil?: any;
  CEstado?: any;
  ITipoIdent?: ITipoIde;
}

export class CCiudad{
  ICodigo?: any;
  CNombres?: any;
}
