import { Injectable } from '@angular/core';
import { CargaDatosService } from '../ConsumoServicios/carga-datos.service';
import { ResponseDetalleFirmaPagare } from '../../Model/Response/response-detalle-firma-pagare';
import { SolicitudPagare } from '../../Model/DTO/solicitud-pagare';
import { ResponseObtenerDocumento } from '../../Model/Response/response-obtener-documento';
import { RespuestaCiudad } from '../../Model/Response/response-ciudades';
import { RespuestaTipoPerfil } from '../../Model/Response/RespuestaTipoPerfil';

@Injectable({
  providedIn: 'root'
})
export class CargaDatosImpService {

  constructor(private cargaDatosService: CargaDatosService) { }

  tipoPerfil() {
    return new Promise(resolve => {
      this.cargaDatosService.tipoPerfil().subscribe(
        (response: RespuestaTipoPerfil) => {          
          resolve(response);
        }
      )
    });
  }
  
  findAlltipoPerfil() {
    return new Promise(resolve => {
      this.cargaDatosService.findAlltipoPerfil().subscribe(
        (response: RespuestaTipoPerfil) => {          
          resolve(response);
        }
      )
    });
  }

  tipoPerfilCliente() {
    return new Promise(resolve => {
      this.cargaDatosService.tipoPerfilCliente().subscribe(
        (response: RespuestaTipoPerfil) => {
          resolve(response);
        }
      )
    });
  }

  tipoPerfilById(xiCod: string) {
    let lista = [];
    return new Promise(resolve => {
      this.cargaDatosService.tipoPerfilById(xiCod).subscribe(
        (response: any) => {
          lista = response.listaClientes;
          resolve(lista);
        }
      )
    });
  }

  tiposIden() {
    return new Promise(resolve => {
      this.cargaDatosService.tiposIden().subscribe(
        (response: any) => {
          resolve(response);
        }
      )
    });
  }

  tiposIdenById(xCod: string) {
    return new Promise(resolve => {
      this.cargaDatosService.tiposIdenById(xCod).subscribe(
        (response: any) => {
          resolve(response.LstTiposIdent['TipoIdentificacionDTO'][0].SDescripcion)
        }
      )
    });
  }


  cargarDetalleFirmaDocumento(xiIdProceso: string) {
    return new Promise(resolve => {
      this.cargaDatosService.obtenerDetalleFirmaDocumento(xiIdProceso).subscribe(
        (responseDetalleFirma: ResponseDetalleFirmaPagare) => {
          resolve(responseDetalleFirma);
        }
      );
    });
  }


  obtenerDocumentoPagare(solicitudPagare: SolicitudPagare) {
    return new Promise(resolve => {
      this.cargaDatosService.obtenerDocumentoPagare(solicitudPagare).subscribe(
        (response: ResponseObtenerDocumento) => {
          if (response != null && response.respuestaBase != null && response.respuestaBase.codigo == 1) {
            resolve(response);
          } else {
            resolve("ERROR");
          }
        }
      );
    });
  }

  obtenerCiudades(){
    return new Promise(resolve => {
      this.cargaDatosService.obtenerCiudades().subscribe(
        (res: RespuestaCiudad) => {
          resolve(res);
        }
      )
    })
  }


}
