import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseAuthAPI } from '../../Model/Response/response-auth-api';
import { RequestAuthAPI } from '../../Model/DTO/request-auth-api';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  public url = environment.URL_SERVICE + "/AuthAPIFecyr";
  public headers = new HttpHeaders(
    {
      "Content-Type": "application/json;charset=UTF-8",
    }
  );

  constructor(private http: HttpClient) { }

  generarToken(requestAuthApi: RequestAuthAPI) {
    let path = this.url + "/AuthFecyr";
    return this.http.post<ResponseAuthAPI>(path, requestAuthApi, { headers: this.headers });
  }

}
