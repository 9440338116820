<ngx-spinner name="sp6" type="timer" size="medium">
  <p style="color: white"> Espere un momento... </p>
</ngx-spinner>
<div class="contenedor">
  <form class="login100-form validate-form" ngNativeValidate #formCambiarClave="ngForm"
      (ngSubmit)="CambiarClaveWs(formCambiarClave)">
      <div class="row">
          <div class="centro">
              <h1 class="Titulo">Cambiar contraseña</h1>
          </div>
      </div>
      <p-toast position="top-right" key="msgToast"></p-toast>
      <div class="row centro">
          <p class="parrafo">
              Introduce una nueva contraseña. Te recomendamos crear una contraseña
              única. Recuerda que no podrás utilizar la contraseña anterior una ves que
              la hayas cambiado.
          </p>
          <p class="parrafo" *ngIf="alfanumerica">
              Recuerda que la contraseña debe incluir por lo menos una mayúscula , una minúscula, un número y su
              longitud debe
              estar entre {{minimo}} y {{maximo}}
          </p>
          <div class="col-lg-6 col-md-5 col-12">

              <div class="row">
                  <div class="col-12">
                      <label class="txtinput" for="">Contraseña actual</label>
                      <input class="form-control inputLogin" type="password" name="Actual"
                          placeholder="Contraseña actual" #xActual="ngModel" (ngModel)="xActual" required="true"
                          autocomplete="of" />

                  </div>
                  <div class="col-12">
                      <label class="txtinput" for="">Contraseña nueva</label>

                      <input class="form-control inputLogin" id="claveNueva" type="password" name="Nueva"
                          placeholder="Contraseña nueva" #xNueva="ngModel" (ngModel)="xNueva" required="true"
                          autocomplete="of" />

                      <div id="longitud" style="display: none;">
                          <p class="txtValidacion">La contraseña nueva debe tener mínimo {{minimo}} y máximo
                              {{maximo}} dígitos</p>
                      </div>

                      <div id="mayus" style="display: none;">
                          <p class="txtValidacion">La contraseña debe incluir por lo menos una mayúscula</p>
                      </div>
                      <div id="minuscula" style="display: none;">
                          <p class="txtValidacion">La contraseña debe incluir por lo menos una minúscula</p>
                      </div>
                      <div id="numero" style="display: none;">
                          <p class="txtValidacion">La contraseña debe incluir por lo menos un número</p>
                      </div>


                  </div>
                  <div class="col-12">
                      <label class="txtinput" for="">Confirma la contraseña</label>
                      <input class="form-control inputLogin" type="password" name="ConfirmarNueva"
                          #xConfirmarNueva="ngModel" (ngModel)="xConfirmarNueva" placeholder="Confirma la contraseña"
                          required="true" autocomplete="of" />
                  </div>

              </div>
          </div>

          <div class="col-lg-6 col-md-5 col-12 ajusteBoton">
              <div class="row">
                  <div class="centro">
                      <input type="submit" class="btn  btnLogin" value="cambiar">
                  </div>
              </div>
          </div>
      </div>
  </form>
</div>

